import { useTranslation } from "react-i18next";
import './handyMenu.css';

export const HandyMenu = () => {
    const { i18n, t } = useTranslation();

    const select = (e) => {
        // console.log(e.target)
    }

    return (
        <div className="navbar">
            <a href="/" className="navitem" onClick={() => select()} data-clr="#fff6cc">
                <div className="indicator"></div>
                <div className="hoverindicator"></div>
                <i className="fa-solid fa-house-chimney"></i>
                <p>{t("home")}</p>
            </a>

            <a href="#services" className="navitem" onClick={() => select()} data-clr="#fff6cc">
                <div className="indicator"></div>
                <div className="hoverindicator"></div>
                <i className="fa-solid fa-handshake-simple"></i>
                {/* <i className="bx bx-book-alt"></i> */}
                <p>{t("service")}</p>
            </a>
            <a href="#projects" className="navitem active" onClick={() => select()} data-clr="#e4ecfa">
                <div className="indicator"></div>
                <div className="hoverindicator"></div>
                {/* <i className='bx bx-briefcase-alt'></i> */}
                <i className="fa-solid fa-briefcase"></i>
                <p>{t("projects")}</p>
            </a>
            <a href="#about" className="navitem" onClick={() => select()} data-clr="#fff6cc">
                <div className="indicator"></div>
                <div className="hoverindicator"></div>
                <i className="fa-solid fa-people-group"></i>
                <p>{t("about")}</p>
            </a>
            <a href="/#contact" className="navitem" onClick={() => select()} data-clr="#fff6cc">
                <div className="indicator"></div>
                <div className="hoverindicator"></div>
                <i className="fa-solid fa-envelope"></i>
                <p>{t("contact")}</p>
            </a>
        </div>

    );
};