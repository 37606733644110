import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Menu } from "./components/menu/menu"
import { About } from "./pages/about/about"
import { Home } from "./pages/home/home"
import { Contact } from "./components/contact/contact"
import { HandyMenu } from './components/handyMenu/handyMenu';
import { Estimate } from './pages/estimate/estimate';
import background from './assets/background.png';

function App() {
  return (
    <BrowserRouter>
        <img className="background" src={background} alt="Main" />
        <Menu />
        <Routes className='container'>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/estimation" element={<Estimate />} />
        </Routes>
        <HandyMenu />
    </BrowserRouter>
  );
}

export default App;
