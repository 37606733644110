import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { LANGUAGES } from "../../constants/index";
import './menu.css';
import logo from '../../assets/allventures_sh.png';
import { useState } from 'react';

const isActive = ({ isActive }) => `link ${isActive ? "active" : ""}`;

export const Menu = () => {
  const { i18n, t } = useTranslation();

  const [menuVisible, setMenuVisible] = useState(true);

  const onChangeLang = (e) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
  };

  return (
    <nav>
     <a href="/"><img src={logo} alt="Logo"/></a>
      <div className="flex-row-center">
        <div 
        className={`menu-options flex-row-center ${menuVisible ? '' : 'hidden'}`}>
          <select defaultValue={i18n.language} onChange={onChangeLang}>
            {LANGUAGES.map(({ code, label }) => (
              <option key={code} value={code}>
                {label}
              </option>
            ))}
          </select>
          <NavLink to="/estimation">
            <div className="buttonBox">
              <button className="button">{t("estimate")}</button>
            </div>
          </NavLink>
        </div>
      </div>

    </nav>
  );
};