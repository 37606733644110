
import { useTranslation } from "react-i18next";
import { Contact } from "../../components/contact/contact"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';

import "./home.css"
import 'swiper/css';

import flutter from '../../assets/flutter.png';
import python from '../../assets/python.png';
import django from '../../assets/django.svg';
import nodejs from '../../assets/nodejs.png';
import angular from '../../assets/angular.png';
import react from '../../assets/react.png';
import jenkins from '../../assets/jenkins.png';
import trawell from '../../assets/trawell.png';
import KITS from '../../assets/KITS.png';
import cassini from '../../assets/cassini.svg';
import sun from '../../assets/sun.png';
import kpt from '../../assets/kpt.svg';
import kms from '../../assets/kms.png';
import gallager from '../../assets/gallager.png';
import panel from '../../assets/panel.png';
import landingpage from '../../assets/landingpage.png';
import { About } from "../about/about";

export const Home = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <>
      <main>
        {
          currentLanguage === 'pl' ?
            <p>
              Odkryj <div>moc współpracy</div> z naszym 
              <br></br>
              SOFTWARE HOUSE 
              <br></br>
              Twoje <div>marzenia</div>, nasze <div>doświadczenie</div> !
            </p> :
            <p>
              Discover <div>the power of collaboration</div> with our
              <br></br>
              SOFTWARE HOUSE
              <br></br>
              Your <div>dreams</div>, our <div>expertise</div>!

            </p>
        }

      </main>
      <section id="services">
        <div>
          <h3>{ t("mobileAppOffert")}</h3>
          <p>
            { t("mobileAppOffertDescription")}
          </p>
        </div>
        <div>
          <h3>{ t("webAppOffert")}</h3>
          <p>
            { t("webAppOffertDescription")}
          </p>
        </div>
        <div>
          <h3>{ t("marketingOffert")}</h3>
          <p>
            { t("marketingOffertDescription")}
          </p>
        </div>
      </section>

      <section id="projects">
        {/* <h1>Technologies we use</h1> */}

        <Swiper
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            400:{
              slidesPerView:2,
              spaceBetween: 10
            },
            639: {
              slidesPerView: 3,
              spaceBetween: 20
            },
            950:{
              slidesPerView:4,
              spaceBetween: 50
            },
            1000:{
              slidesPerView:5,
              spaceBetween: 50
            }
          }}
          modules={[Autoplay]}
          autoplay={{
            delay: 1500,
            pauseOnMouseEnter: true,
            disableOnInteraction: false
          }}
          loop
        >
          <SwiperSlide><img src={flutter}></img></SwiperSlide>
          <SwiperSlide><img src={python}></img></SwiperSlide>
          <SwiperSlide><img src={django}></img></SwiperSlide>
          <SwiperSlide><img src={nodejs}></img></SwiperSlide>
          <SwiperSlide><img src={angular}></img></SwiperSlide>
          <SwiperSlide><img src={react}></img></SwiperSlide>
          <SwiperSlide><img src={jenkins}></img></SwiperSlide>
        </Swiper>

      </section>

      <section id="successes">
        <h2>{ t("ourProjects")}</h2>
        <div className="project">
          <div className="project-presentation">
            <h2>{ t('trawellMobile')}</h2>
            <p>{ t('trawellMobileDescription')}</p>
            <div className="technology-chips">
              <div className="technology-chip">Dart</div>
              <div className="technology-chip">Flutter</div>
              <div className="technology-chip">Python</div>
              <div className="technology-chip">Django</div>
              <div className="technology-chip">Figma</div>
            </div>
          </div>
          <img src={trawell}></img>
        </div>

        <div className="project">
          <img src={panel}></img>
          <div className="project-presentation">
            <h2>{ t('trawellWebPanel')}</h2>
            <p>{ t('trawellWebPanelDescription')}</p>
            <div className="technology-chips">
              <div className="technology-chip">TypeScript</div>
              <div className="technology-chip">Angular</div>
              <div className="technology-chip">Firebase</div>
            </div>
          </div>
        </div>

        <div className="project">
          <div className="project-presentation">
            <h2>{ t('gallager')}</h2>
            <p>{ t('gallagerDescription')}</p>
            <div className="technology-chips">
              <div className="technology-chip">Dart</div>
              <div className="technology-chip">Flutter</div>
              <div className="technology-chip">Figma</div>
            </div>
          </div>
          <img src={gallager}></img>
        </div>

        <div className="project">
          <img src={landingpage}></img>
          <div className="project-presentation">
            <h2>{ t('landingPage')}</h2>
            <p>{ t('landingPageDescription')}</p>
            <div className="technology-chips">
              <div className="technology-chip">HTML</div>
              <div className="technology-chip">CSS</div>
              <div className="technology-chip">JavaScript</div>
              <div className="technology-chip">React</div>
            </div>
          </div>
        </div>
      </section>

      <section id="success">
        <h2>{t("ourSuccess")}</h2>
        <div>
          <Swiper
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              400:{
                slidesPerView:2,
                spaceBetween: 10
              },
              639: {
                slidesPerView: 3,
                spaceBetween: 20
              },
              950:{
                slidesPerView:3,
                spaceBetween: 50
              },
              1000:{
                slidesPerView:4,
                spaceBetween: 50
              }
            }}
            modules={[Autoplay]}
            autoplay={{
              delay: 1500,
              pauseOnMouseEnter: true,
              disableOnInteraction: false
            }}
            loop
          >
            <SwiperSlide>
              <img src={cassini}></img>
              <p>{ t("cassiniWinner")}</p>
            </SwiperSlide>
            <SwiperSlide>
              <img src={kpt}></img>
              <p>KPT Pitch Fighting Club</p>
            </SwiperSlide>
            <SwiperSlide>
              <img src={sun}></img>
              <p>SUN Hubs Netowrk Incubator</p>
            </SwiperSlide>
            <SwiperSlide>
              <img src={KITS}></img>
              <p>KrK Innotech Starter</p>
            </SwiperSlide>
            <SwiperSlide>
              <img src={kms}></img>
              <p>{ t("audienceWinner")}</p>
            </SwiperSlide>
            <SwiperSlide>
              <img src={kms}></img>
              <p>{ t("juryWinner")}</p>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      <section id="about">
        <About />
      </section>

      <section id="contact">
        <p>
        {t("contactDescription")}
        </p>
        <Contact />
      </section>
    </>
  );
};