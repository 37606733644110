
import "./estimate.css"
import { useTranslation } from "react-i18next";
import { useState } from 'react';
import axios from 'axios';
import { FormModal } from '../../components/formModal/formModal';

const SERVICE_ID = "service_65jjnoq";
const TEMPLATE_ID = "template_uzanxer";
const PUBLIC_KEY = "YFfxiHiLRuZ0n1Lw5";

export const Estimate = () => {
    const { i18n, t } = useTranslation();
    const [form, setForm] = useState({
        mobile_app: "off",
        web_app: "off",
        web_page: "off",
        other: "off",
        marketing: "off",
        prototype: "off"
    });
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(null);
    const [message, setMessage] = useState("");

    const handleForm = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        form[name] = value;
        setForm({ ...form });
    }

    const handleSubmit = async () => {
        const data = {
            service_id: SERVICE_ID,
            template_id: TEMPLATE_ID,
            user_id: PUBLIC_KEY,
            template_params: {
                ...form,
                'to_name': 'Allventures',
                'g-recaptcha-response': captchaValue
            },
        };

        try {
            const response = await axios.post("https://api.emailjs.com/api/v1.0/email/send", data)
            setCaptchaValue(null);
            setForm({});
            if (response.status === 200) {
                setMessage(t("contactFormSend"));
            }
        } catch (error) {
            setMessage(t("contactFormError"));
        }
    };
    return (
        <div className='estimate-section'>
            {formSubmitted && <FormModal
                setFormSubmitted={setFormSubmitted}
                captchaValue={captchaValue}
                setCaptchaValue={setCaptchaValue}
                handleSubmit={handleSubmit}
                message={message}
                setMessage={setMessage}
            />}
            <form onSubmit={(e) => { e.preventDefault(); setFormSubmitted(true) }}>
            <div className="form-data-container">
                <p>
                    {t("estimationDescription")}
                </p>
                <div>
                    {t("nonDisclosureAgreement.0")}
                    <a href="https://docs.google.com/document/d/1wNnchlA2x8infjaMMl1EU8aanc7iBdklwiucsGl8Jh4/edit?usp=sharing">{t("nonDisclosureAgreement.1")} </a>
                    {t("nonDisclosureAgreement.2")}
                </div>
                <label>
                    {t("email")}
                </label>
                <input type="email" name="user_email" onChange={(e) => handleForm(e)} />
                <label>
                    {t("companyOrName")}
                </label>
                <input type="text" name="client_name" onChange={(e) => handleForm(e)} />
                <label>
                    {t("phone")}
                </label>
                <input type="text" name="phone" onChange={(e) => handleForm(e)} />
            </div>
            <div className="form-checkbox-container">
                <div>
                    <p>{t("product")}</p>
                    <label>
                        <input name="mobile_app" type="checkbox" onChange={(e) => handleForm(e)} />
                        <i className="fa-solid fa-mobile-screen-button"></i>
                        {t("mobileApp")}
                    </label>
                    <label>
                        <input name="web_app" type="checkbox" onChange={(e) => handleForm(e)} />
                        <i className="fa-solid fa-laptop"></i>
                        {t("webApp")}
                    </label>
                    <label>
                        <input name="web_page" type="checkbox" onChange={(e) => handleForm(e)} />
                        <i className="fa-solid fa-globe"></i>
                        {t("webPage")}
                    </label>
                    <label>
                        <input name="other" type="checkbox" onChange={(e) => handleForm(e)} />
                        <i className="fa-solid fa-laptop-code"></i>
                        {t("other")}
                    </label>
                </div>
                <div>
                    <p>
                        {t("service")}
                    </p>
                    <label>
                        <input name="marketing" type="checkbox" onChange={(e) => handleForm(e)} />
                        <i className="fa-solid fa-rectangle-ad"></i>
                        {t("marketing")}
                    </label>
                    <label>
                        <input name="prototype" type="checkbox" onChange={(e) => handleForm(e)} />
                        <i className="fa-solid fa-compass-drafting"></i>
                        {t("prototype")}
                    </label>
                </div>
                {/* <div>
                        <label for="file">ZAŁĄCZ PLIKI (OPCJONALNE)</label>
                        <input type="file" id="file" name="file" multiple />
                    </div> */}
                <legend>
                    {t("budget")}
                </legend>
                <div id="budget">
                    <div className="card">
                        <input type="radio" id="option1" name="selection" value="<10" onChange={(e) => handleForm(e)} />
                        <label><i className="fa-solid fa-less-than"></i> 10k€</label>
                    </div>

                    <div className="card">
                        <input type="radio" id="option2" name="selection" value="10-50" onChange={(e) => handleForm(e)} />
                        <label>10k€-50k€</label>
                    </div>

                    <div className="card">
                        <input type="radio" id="option3" name="selection" value=">50" onChange={(e) => handleForm(e)} />
                        <label><i className="fa-solid fa-less-than fa-rotate-180"></i> 150k€</label>
                    </div>
                </div>

            </div>
            <div className="form-details-container">
                <label>
                    {t("projectDetails")}
                </label>
                <textarea name="message" onChange={(e) => handleForm(e)} />
                <label>
                    {t("projectStart")}
                </label>
                <input type="text" name="project_start" onChange={(e) => handleForm(e)} />
                <label>
                    <input type="checkbox" id="permissions" name="permissions" onChange={(e) => handleForm(e)} />
                    {t("agreement")}
                </label>

                <div className='buttonBox'>
                    <button className='button' type="submit">SEND</button>
                </div>
            </div>

        </form>
        </div>
    );
};