import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          title: "Multi-language app",
          label: "Select another language!",
          about: "ABOUT",
          home: "HOME",
          projects: "PROJECTS",
          service: "SERVICES",
          contact: "CONTACT",
          estimate: "Get an estimate",
          ourSuccess: "OUR SUCCESS",
          audienceWinner: "DEMO DAY - audience winner",
          juryWinner: "DEMO DAY - the 3rd jury place",
          cassiniWinner: "CASSINI hackathon'22 1. miejsce",
          contactDescription: "Do you have any questions or need assistance? We are available to help you. Please fill out the form below, and we will respond as quickly as possible!",
          name: "Name",
          email: "Email",
          message: "Message",
          send: "send",
          meetOurTeam: "MEET OUR TEAM",
          about1: "Each of us know each other very well, and our contact and mutual relations have lasted for 8 years between several of us. We have proved that we can work very well together.",
          about2: "We have achieved many successes together, both on a national and international level. We established a software house because our passion lies in creating solutions through our experience and the power of collaboration.",
          contactFormSend: "The message has been sent. Thank you! We will respond to your inquiries shortly.",
          contactFormError: "An error occurred. Please try submitting the form again.",
          ourProjects: "OUR PROJECTS",
          trawellMobile: "TRAWELL MOBILE APP",
          trawellMobileDescription: "Trawell is a virtual tourist guide that, through gamification and artificial intelligence, leads you to unique places while learning from your travel experiences. The application supports city exploration, allowing you to dynamically fill your time during your trip. Upon launching the application, choose a location from the prepared deck of places near your current position. Each card hides incredible facts and secrets—simply tap and rotate the card. The built-in map will be your reliable guide, and when you reach your destination, a challenge awaits! Solve puzzles and tasks, earn points, and compete with other travelers for the title of the best explorer. Your achievements will be visible in your user profile, where you can review the history of completed challenges. Move around the city and explore new cards with interesting places.",
          trawellWebPanel: "TRAWELL WEB PANEL",
          trawellWebPanelDescription: "The panel allows you to create, insert and modify places to visit, and define new challenges/games that are available for the Trawell Mobile application. It is a hub for creators who revise, translate into many languages ​​and introduce new places. The application returns statistics about the application and the ranking of users in cities. As a B2B platform, it allows you to start cooperation with customers. The panel is also adapted to be used in a mobile browser.",
          gallager: "GALLAGER",
          gallagerDescription: "We have created comprehensive documentation along with a sample interface for a dog training support application, designed for both trainers and pet owners. The application facilitates the learning of dogs in an intuitive and individualized manner, with the support of the trainer through the execution of tasks and exercises.",
          landingPage: "LANDING PAGE",
          landingPageDescription: "Our websites seamlessly blend functionality with aesthetics. Each project is meticulously designed, focusing on both appearance and positive user experiences. Our websites come equipped with comprehensive meta tags, integrated user analysis, and visual tracking of user movements and behaviors on the site. We provide the option for multilingual support and the integration of customized contact forms. Leveraging our proprietary technology, our websites are unique and stand out from the competition.",
          estimationDescription: "Experience and effective teamwork are our strengths! Combined with our knowledge and technical skills, we will plan the process and provide a quote for your project. The provided information is secure, and we only use it for the purpose of estimation. Benefit from our knowledge and expertise!",
          nonDisclosureAgreement: ["If you require a confidentiality agreement,", "download the file,", "fill out the form, and send it to management@allventures.eu. We will declare confidentiality and get in touch with you regarding the project implementation."],
          companyOrName: "Company or Your First Name, Last Name",
          phone: "Phone number",
          product: "PRODUCT",
          mobileApp: "Mobile app",
          webApp: "Web app",
          webPage: "Web page",
          other: "Other",
          marketing: "Marketing",
          prototype: "Prototyping",
          budget: "BUDGET",
          projectDetails: "Project details",
          projectStart: "Project start",
          agreement: "I give consent for the processing of my personal data for the purpose of preparing a quote by Allventures sp. z o.o.",
          mobileAppOffert: "MOBILE APPLICATIONS",
          mobileAppOffertDescription: "We combine our skills, experience, and passions. We have a defined process that we successfully follow when building an application for the client. We prioritize intuitiveness, ease of use, and good design. We develop applications for both Android and iOS platforms using the Flutter framework, supported by a robust server.",
          webAppOffert: "WEB APPLICATIONS",
          webAppOffertDescription: "We understand how crucial it is to manage organization, application resources, and users. We create web applications that facilitate easy and intuitive management of content/translations/statistics. If you need an application that will streamline your business operations and automate solutions, we will contribute our full range of experiences to deliver a product that will take your business to a new dimension.",
          marketingOffert: "MARKETING",
          marketingOffertDescription: "We optimize your online presence! Our Software House offers comprehensive social media management, including creative content creation for videos and graphics. We expand reach, engage users, and build a strong brand. We have expertise in promoting activities on LinkedIn, TikTok, and Instagram. Strengthen your position with us!",
        },
      },
      pl: {
        translation: {
          title: "Wielojęzykowa apka",
          label: "Wybierz inny język",
          about: "O NAS",
          home: "START",
          projects: "PROJEKTY",
          service: "USŁUGI",
          contact: "KONTAKT",
          estimate: "Wyceń projekt",
          ourSuccess: "NASZE SUKCESY",
          audienceWinner: "DEMO DAY - nagroda publiczności",
          juryWinner: "DEMO DAY - 3 miejsce jury",
          cassiniWinner: "CASSINI hackathon'22 winner",
          contactDescription: "Jeżeli masz pytania lub potrzebujesz pomocy, skontaktuj się z nami. Jesteśmy dostępni, aby Ci pomóc. Wypełnij poniższy formularz, a my odpowiemy najszybciej, jak to możliwe!",
          name: "Name",
          email: "Email",
          message: "Wiadmość",
          send: "wyślij",
          meetOurTeam: "POZNAJ NASZ ZESPÓŁ",
          about1: "Każdy z nas zna siebie bardzo dobrze, a nasze kontakty i wzajemne relacje trwają od 8 lat między kilkoma z nas. Udowodniliśmy, że potrafimy bardzo dobrze współpracować.",
          about2: "Odnieśliśmy wspólnie wiele sukcesów, zarówno krajowych jak i międzynarodowych. Założyliśmy software house, ponieważ naszą pasją jest tworzenie rozwiązań poprzez nasz doświadczenie i siłę współpracy.",
          contactFormSend: "Wiadomość została wysłana. Dziękujemy! Wkrótce odpowiemy na Twoje pytania.",
          contactFormError: "Wystąpił błąd. Spróbuj wysłać formularz ponownie.",
          ourProjects: "NASZE PROJEKTY",
          trawellMobile: "APLIKACJA MOBILNA TRAWELL",
          trawellMobileDescription: "Trawell to wirtualny przewodnik turysty, który dzięki grywalizacji i sztucznej inteligencji prowadzi Cię do wyjątkowych miejsc ucząc się Twoich doświadczeń z podróży. Aplikacja wspiera zwiedzanie miast, pozwala dynamicznie wypełnić Twój czas w czasie wycieczki. Po uruchomieniu aplikacji wybierz miejsce z przygotowanej talii miejsc znajdujących się w pobliżu Twojej lokalizacji. Każda karta skrywa niesamowite ciekawostki i tajemnice, wystarczy dotknąć i obrócić kartę. Wbudowana mapa będzie Twoim niezawodnym przewodnikiem, a gdy dotrzesz do celu, czeka na Ciebie wyzwanie! Rozwiązuj zagadki i zadania, zdobywaj punkty i ścigaj się z innymi podróżnikami o tytuł najlepszego odkrywcy. Twoje osiągnięcia będą widoczne w profilu użytkownika, gdzie będziesz mógł przeglądać historię zrealizowanych wyzwań. Poruszaj się po mieście i przeglądaj nowe karty z miejscami.",
          trawellWebPanel: "TRAWELL INTERNETOWY PANEL",
          trawellWebPanelDescription: "Panel umożliwia tworzenie, wstawianie i modyfikowanie miejsc do odwiedzenia, definiowanie nowych wyzwań/ gier, które dostępne są dla aplikacji Trawell Mobile. Jest hubem dla twórców, którzy weryfikują, tłumaczą na wiele języków i wprowadzają nowe miejsca. Aplikacja zwraca statystyki dotyczące aplikacji oraz ranking użytkowników w miastach. Jako platforma B2B umożliwia rozpoczęcie współpracy z klientami. Panel przystosowany jest również do korzystania w przeglądarce mobilnej.",
          gallager: "GALLAGER",
          gallagerDescription: "Stworzyliśmy pełną dokumentację, wraz z przykładowym interfejsem aplikacji wspierającej szkolenie psów - zarówno dla trenerów jak i właścicieli pupilów. Aplikacja wspomaga naukę czworonogów w intuicyjny i indywidualny sposób przy wsparciu trenera poprzez realizację zadań i ćwiczeń.",
          landingPage: "STRONA INTERNETOWA",
          landingPageDescription: "Nasze strony łączą funkcjonalność z estetyką. Każdy projekt jest starannie zaprojektowany zarówno pod względem wyglądu jak i pozytywnych doświadczeń użytkownika. Nasze strony są uzupełnione pełnym wymiarem metatagów, podłączone pod analizę użytkowników oraz analizę wizualną ruchów i zachowań gości na stronie. Dajemy możliwość wprowadzania tłumaczeń oraz połączenia dedykowanych formularzy kontaktowych. Używamy własnej opracowanej technologii, dzięki czemu nasze strony są wyjątkowe i odróżniają się od konkurencji.",
          estimationDescription: "Doświadczenie i sprawna komunikacja w zespole są naszą siłą! W połączeniu z posiadaną wiedzą i umiejętnościami technicznymi zaplanujemy proces i przygotujemy wycenę Twojego projektu. Podane informacje są bezpieczne i używamy ich tylko na potrzeby wyceny. Skorzystaj z naszej wiedzy i doświadczenia!",
          nonDisclosureAgreement: ["Jeśli potrzebujesz umowy poufności,", "pobierz plik,", "wypełnij formularz i wyślij na management@allventures.eu. Zadeklarujemy swoją poufność i skontaktujemy się z Tobą w sprawie realizacji projektu."],
          companyOrName: "Firma lub Twoje imię, nazwisko",
          phone: "Numer telefonu",
          product: "PRODUKT",
          mobileApp: "Aplikacja mobilna",
          webApp: "Aplikacja internetowa",
          webPage: "Strona internetowa",
          other: "Inne",
          marketing: "Marketing",
          prototype: "Prototypowanie",
          budget: "BUDŻET",
          projectDetails: "Szczegóły projektu",
          projectStart: "Start projektu",
          agreement: "wyrażam zgodę na przetwarzanie moich danych osobowych w zakresie przygotwania wyceny przez Allventures sp. z o.o.",
          mobileAppOffert: "APLIKACJE MOBILNE",
          mobileAppOffertDescription: "Łączymy nasze umiejętności, doświadczenie i pasje. Mamy zdefiniowany proces, który pomyślnie realizujemy budując aplikację dla Klienta. Stawiamy na intuicyjność, łatwość użycia i dobry design. Budujemy aplikacje przeznaczone na obie platformy Android i iOS korzystając z frameworka Flutter wspartego przez solidny serwer. ",
          webAppOffert: "APLIKACJE INTERNETOWE",
          webAppOffertDescription: "Wiemy jak ważne jest zarządzanie organizacją, zasobami aplikacji czy użytkownikami. Tworzy aplikacje internetowe, które pomagają w łatwy i intuicyjny sposób zarządzać treściami/ tłumaczeniami/ statystykami. Jeśli potrzebujesz aplikacji, która ułatwi pracę Twojego biznesu i zautomatyzuje rozwiązania dołożymy pełnię naszych doświadczeń by dostarczyć produkt, który wpłynie na nowy wymiar Twojego biznesu.",
          marketingOffert: "MARKETING",
          marketingOffertDescription: "Optymalizujemy Twoją obecność online! Nasz Software House oferuje kompleksową obsługę social media, włącznie z kreatywnym tworzeniem treści video i grafik. Zwiększamy zasięg, angażujemy użytkowników i budujemy silną markę. Posiadamy kompetencje w zakresie promocji działań na Linkedinie, Tiktoku i Instagramie. Wzmocnij swoją pozycję z nami!",
        },
      },
    },
  });

export default i18n;