import React, { useState } from 'react';
import "./contact.css"
import axios from 'axios';
import { useTranslation } from "react-i18next";
import { FormModal } from '../formModal/formModal';

const SERVICE_ID = "service_65jjnoq";
const TEMPLATE_ID = "template_j7u0zwt";
const PUBLIC_KEY = "YFfxiHiLRuZ0n1Lw5";

export const Contact = () => {
    const { i18n, t } = useTranslation();
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(null);
    const [form, setForm] = useState({});
    const [message, setMessage] = useState("");

    const handleForm = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        form[name] = value;
        setForm({...form});
    }

    const handleSubmit = async () => {

        const data = {
            service_id: SERVICE_ID,
            template_id: TEMPLATE_ID,
            user_id: PUBLIC_KEY,
            template_params: {
                ...form,
                'to_name': 'Allventures',
                'g-recaptcha-response': captchaValue
            },
        };

        try {
            const response = await axios.post("https://api.emailjs.com/api/v1.0/email/send", data)
            setCaptchaValue(null);
            setForm({});
            if(response.status === 200) {
                setMessage(t("contactFormSend"));
            }
        } catch (error) {
            setMessage(t("contactFormError"));
        }
    };

    return (
        <div className='contact-section'>
            { formSubmitted && <FormModal 
                setFormSubmitted={setFormSubmitted}
                captchaValue={captchaValue}
                setCaptchaValue={setCaptchaValue}
                handleSubmit={handleSubmit}
                message={message}
                setMessage={setMessage}
                />}
            <form onSubmit={(e) => {e.preventDefault(); setFormSubmitted(true)}}>
                <label>{t("name")}</label>
                <input type="text" name="from_name" onChange={(e) => handleForm(e)} />
                <label>{t("email")}</label>
                <input type="email" name="from_email" onChange={(e) => handleForm(e)} />
                <label>{t("message")}</label>
                <textarea name="message" onChange={(e) => handleForm(e)} />
                <div className="last-form-line">
                    <div className='buttonBox'>
                        <button className='button' type="submit">{t("send")}</button>
                    </div>
                </div>
               
            </form>
            <div>
                <div className='social-media'>
                    <a href='https://www.linkedin.com/company/86671615/'>
                        <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                    <a href='https://www.facebook.com/profile.php?id=100092505494503'>
                        <i className="fa-brands fa-facebook-f"></i>
                    </a>
                    <a href='https://www.instagram.com/allventures_'>
                        <i className="fa-brands fa-instagram"></i>
                    </a>
                    <a href='https://www.tiktok.com/@trawell_allventures'>
                        <i className="fa-brands fa-tiktok"></i>
                    </a>
                </div>
                <i className="fa-regular fa-envelope"> management@allventures.eu</i>
            </div>

        </div>

    );
};